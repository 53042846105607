import React, { Component, useState } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  SendOtp,
  ConfirmOtp,
  ResetPassword,
} from "../../../../BackendData/FetchApi";

const ForgetPassPage = () => {
  const navigate = useNavigate();

  const [MobileNumber, setMobileNumber] = useState(0);
  const [OtpCheck, setOtpCheck] = useState(false);
  const [Otp, setOtp] = useState(0);
  const [CheckPasswordField, setCheckPasswordFiled] = useState(false);
  const [Password, setPassword] = useState(null);
  const [ConfirmPassword, setConfirmPassword] = useState(null);

  const handleMobileNumber = () => {
    console.log(MobileNumber);
    if (MobileNumber !== 0) {
      let abc = {
        mobile: MobileNumber,
      };
      SendOtp(abc).then((res) => {
        if (res === false) {
          alert(
            "This mobile number not valid please check number or sing up for new Account"
          );
        } else {
          setOtpCheck(true);
        }
      });
    }
  };

  const handleSentOtp = () => {
    if (Otp !== 0) {
      let MakeOtpReq = {
        mobile: MobileNumber,
        otp: Otp,
      };
      ConfirmOtp(MakeOtpReq).then((res) => {
        if (res === true) {
          setCheckPasswordFiled(true);
        } else {
          alert("please provide valid otp");
        }
      });
    } else {
      alert("please provide valid otp");
    }
  };
  const handleResetPassword = () => {
    if (Password !== null && ConfirmPassword !== null) {
      if (Password === ConfirmPassword) {
        let MakePass = {
          mobile: MobileNumber,
          password: Password,
        };
        ResetPassword(MakePass).then((res) => {
          if (res === true) {
            alert("Successfully change the password");
            navigate("/login");
          }
        });
      } else {
        alert("Password & Confirm password are not same");
      }
    } else {
      alert("Password or Confirm password is blank");
    }
  };

  return (
    <Container className="login_wrapper">
      <Card style={{ width: "18rem" }}>
        <Card.Header>Forget Password</Card.Header>
        <Card.Body>
          {CheckPasswordField === true ? (
            <>
              <label className="m-2" htmlFor="password">
                Password
              </label>
              <input
                className="m-2"
                id="password"
                type="password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
              <label className="m-2" htmlFor="Cpassword">
                Confirm Password
              </label>
              <input
                className="m-2"
                id="Cpassword"
                type="password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm password"
              />
              <Button className="m-2" onClick={handleResetPassword}>
                Confirm
              </Button>{" "}
            </>
          ) : (
            <>
              {OtpCheck === false ? (
                <>
                  <Container className="d-flex row  ">
                    <label className="m-2" htmlFor="mobile">
                      Mobile Number
                    </label>
                    <input
                      id="mobile"
                      className="m-2"
                      type="number"
                      value={MobileNumber === 0 ? null : MobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      placeholder="your mobile number"
                      pattern="[0-9]"
                      required
                    />
                    <Button className="m-2" onClick={handleMobileNumber}>
                      Submit
                    </Button>{" "}
                  </Container>
                </>
              ) : (
                <>
                  <label className="m-2" htmlFor="otp">
                    Input Otp
                  </label>
                  <input
                    id="otp"
                    type="number"
                    value={Otp === 0 ? null : Otp}
                    className="m-2"
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="your 4 digit otp"
                  />
                  <Button className="m-2" onClick={handleSentOtp}>
                    Confirm
                  </Button>{" "}
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
export default ForgetPassPage;
