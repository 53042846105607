import React, { Component, useEffect, useState } from "react";
import ModalCmp from "../Modal";

const CompleteTransectionCMP = ({ data }) => {
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    setGetData(data);
  }, [data]);

  return (
    <>
      {" "}
      <table style={{ width: "70%",textAlign:'center' }}>
        <thead>
          <tr>
            <th>Sl</th>
            <th>Date</th>
            <th>Order Id</th>
            <th>Total Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {getData &&
            getData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.created_at}</td>
                <td>{item.order_number}</td>
               <td>{item.total_amount}</td>

                <td>
                  <ModalCmp title={"complete"} total={item.total_amount} orderId={item.order_id} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
export default CompleteTransectionCMP;
