import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HotSale,AllProduct } from "../../../BackendData/FetchApi";
import HotProductItem from "../../Component/HotProductItem";
import ProductItem from "../../Component/ProductItem";

const ProductCarousel = ({title}) => {
  const [HotProductList, setHotProductList] = useState([]);
  const [AllProductList, setAllProductList] = useState([]);
      useEffect(() => {
        HotSale().then((res) => setHotProductList(res));
        AllProduct().then((res) => {
        let getProduct =  res.filter((item) => item.price !== item.temp_price)
        setAllProductList(getProduct)
        })
      }, []);
  switch (title) {
    case 'hotProduct':
      return (
        <>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={2000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
          >
            {HotProductList.map((items, index) => (
              
               <>
                <HotProductItem
                  key={index}
                  productId={items.id}
                  productName={items.name}
                  productImage={items.image}
                  productPrice={items.price}
                  productStock={null}
                  />
                  </>
            
            ))}
          </Carousel>
        </>
      );
    case 'discountProduct':
      return (
        <>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={2000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
          >
            {AllProductList.map((items, index) => (
              
               
                <HotProductItem
                  key={index}
                  productId={items.id}
                  productName={items.name}
                  productImage={items.image}
                  productPrice={items.price}
                  tempPrice={items.temp_price}
                  productStock={null}
                  />
                  
            
            ))}
          </Carousel>
        </>
      );
  
    default:
      break;
  }

};

export default ProductCarousel;
