import React from "react";
import { Outlet, Navigate } from "react-router-dom";


const PrivateRoute = ({ userCheck }) => {
  
  if (userCheck === false) {
    return <Navigate to="/login" replace />;
  } else if(sessionStorage.getItem('CartProductList')!==null){
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
  
  
};

export default PrivateRoute;
