import React, { Component, useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import {
  Container,
  Card,
  Button,
  Badge,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { GetProductDetails } from "../../../BackendData/FetchApi";
import CartList from "../../Component/CartList";
import MySpinner from "../../Component/Spinner";
import SimilarProduct from "./SimilarProduct";


const ProductDetails = () => {
  const [Product, setProduct] = useState(null);
  const [Price, setPrice] = useState(null);
  const [Qty, setQty] = useState(0);
  const [CheckCart, setCheckCart] = useState(null);
  const [FinalCartList, setFinalCartList] = useState(
    JSON.parse(sessionStorage.getItem("CartProductList"))
  );

  let { id } = useParams();

  useEffect(() => {
    
    GetProductDetails(id).then((res) => setProduct(res));
    GetProductDetails(id).then((res) => console.log(res));
    let data = JSON.parse(sessionStorage.getItem("CartProductList"));
    if (data !== null) {
      setCheckCart(true);
    } else {
      setCheckCart(false);
    }
    setQty(1);
  }, [id]);

  // Add Cart product

  const handlePlus = () => {
    if (Qty < Product.qty) {
      let add = Qty;
      add += 1;
      let checkPrice = parseFloat(Product.price) * add;
      setPrice(checkPrice);
      setQty(add);
    }
  };
  const handleMinus = () => {
    if (Qty > 1) {
      let add = Qty;
      add -= 1;
      let checkPrice = parseFloat(Product.price) * add;
      setPrice(checkPrice);
      setQty(add);
    }
  };

  const handleSubmit = () => {
    if (Qty === 1) {
      var MakePrice = parseFloat(Product.price);
      var cartProduct = [
        {
          id: Product.id,
          name: Product.name,
          Qty: Qty,
          price: MakePrice,
        },
      ];
    } else {
      cartProduct = [
        {
          id: Product.id,
          name: Product.name,
          Qty: Qty,
          price: Price,
        },
      ];
    }

    //checking cart and appending data

    let data = JSON.parse(sessionStorage.getItem("CartProductList"));

    if (data !== null) {
      let check = data.findIndex((i) => i.id == id);

      if (check !== -1) {
        let data = JSON.parse(sessionStorage.getItem("CartProductList"));
        data[check] = cartProduct[0];
        sessionStorage.setItem("CartProductList", JSON.stringify(data));
        let dataLast = JSON.parse(sessionStorage.getItem("CartProductList"));
        setFinalCartList(dataLast);
      } else {
        let tempData = JSON.parse(sessionStorage.getItem("CartProductList"));
        tempData.push(cartProduct[0]);

        sessionStorage.setItem("CartProductList", JSON.stringify(tempData));
        let data = JSON.parse(sessionStorage.getItem("CartProductList"));
        setFinalCartList(data);
      }
    } else {
      sessionStorage.setItem("CartProductList", JSON.stringify(cartProduct));
      let data = JSON.parse(sessionStorage.getItem("CartProductList"));
      setFinalCartList(data);
    }
  };

  return (
    <>
      {Product !== null ? (
        <section className="h-100 gradient-custom">
          <div className="container py-5">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-7">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h5 className="mb-0">{Product.name}</h5>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                        <div
                          className="bg-image hover-overlay hover-zoom ripple rounded"
                          data-mdb-ripple-color="light"
                        >
                          <Image
                            src={`https://shop.gtshopltd.com/public/images/product/${Product.image}`}
                            className="w-100"
                            style={{ maxHeight: "200px" }}
                            alt="product-image"
                          />
                          <a href="#!">
                            <div
                              className="mask"
                              style={{
                                backgroundColor: "rgba(251, 251, 251, 0.2)",
                              }}
                            ></div>
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-6 mb-4 mb-lg-0">
                        <p className="mt-5">
                          <strong>{Product.name ? Product.name : null} </strong>
                        </p>
                        <strong className="d-block mb-3">
                          In stock : {Product.qty}{" "}
                        </strong>
                        {/* <button
                          type="button"
                          className="btn btn-primary btn-sm me-1 mb-2"
                          data-mdb-toggle="tooltip"
                          title="Remove item"
                        >
                         
                        </button> */}
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-danger btn-sm mb-2"
                          data-mdb-toggle="tooltip"
                          title="Move to the wish list"
                        >
                        Add Cart
                        </button>
                      </div>

                      <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                        <div
                          className="d-flex mb-4"
                          style={{ maxWidth: "300px", marginTop: "50px" }}
                        >
                          <button
                            className="btn btn-primary px-3 me-2"
                            onClick={handleMinus}
                          >
                            <i className="fas fa-minus"></i>
                          </button>

                          <div className="form-outline">
                            <input
                              type="number"
                              value={Qty}
                              readOnly
                              style={{ width: 50, textAlign: "center" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary px-3 ms-2"
                            onClick={handlePlus}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                          
                        </div>

                        <p className="text-start text-md-center">
                          <strong>
                            {Price === null ? parseFloat(Product.price) : Price}{" "}
                            Tk
                          </strong>
                        </p>
                      </div>
                    </div>

                    <hr className="my-4" />
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <CartList
                  setProductImg={`https://shop.gtshopltd.com/public/images/product/${Product.image}`}
                  setCartProduct={FinalCartList}
                  page={"productDetails"}
                  total={null}
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <MySpinner />
      )}
        
      
      {Product !== null ? <SimilarProduct categoryName={Product.category} /> : null }
    </>
    
  );
};

export default ProductDetails;
