import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "../../Component/Slider";
import Promo from "../../Component/Promo";
import SingleProduct from "../../Component/SingleProduct";
import SideBar from "../../Component/SideBar";
import { fetchCategory } from "../../../BackendData/FetchApi";
import Footer from "./Footer";
import FeaturesProduct from "../Pages/FeaturesProduct";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import '../../../style/TopBar.css'

const TopBar = () => {


  useEffect(() => {
   
  }, []);
  return (
    <Box className="mainView" sx={{ flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid className="sliderStyle">
        <Box  sx={{ flexGrow: 1,height: '50%' }}> 
        <Slider />
        </Box>
          
        </Grid>
        <Grid item xs={12} md={12}>
          <Item><SingleProduct title={"discountProduct"} /></Item>
        </Grid>
        <Grid item xs={12} md={12}>
          <Item> <FeaturesProduct /></Item>
        </Grid>
        <Grid item xs={12} md={12}>
          <Item><Footer /></Item>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TopBar;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
