import React, { Component, useContext, useEffect } from "react";
import { Routes, Route,useNavigate } from "react-router-dom";
import TopBar from "../Pages/Layout/FixedArea/TopBar";
import Login from "../Pages/Layout/Pages/AuthPage/Login.jsx";
import Register from "../Pages/Layout/Pages/AuthPage/Register.jsx";
import AllProductCat from "../Pages/Layout/Pages/AllPrdctCat";
import ProductDetails from "../Pages/Layout/Pages/ProductDetails";
import AuthContext from "../Context/AuthContext";
import CheckLogin from "./CheckLogin";
import PrivateRoute from "./PrivateRoute";
import CheckOut from "../Pages/Layout/Pages/Checkout";
import Profile from "../Pages/Layout/Pages/Profile"
import AuthProfile from "./AuthProfile";
import ForgetPassPage from "../Pages/Layout/Pages/AuthPage/ForgetPassPage";


const AllRoute = () => {
  const user = useContext(AuthContext);
  
  return (
    <>
      <Routes>
        <Route index path="/" element={<TopBar />} />
        <Route path="*" element={<NoData/>} />
        <Route element={<CheckLogin userCheck={user} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path='/forget_password' element={<ForgetPassPage/>} />
        </Route>

        <Route path="/all_product" element={<AllProductCat />} />
        <Route path="/product">
          <Route path=":id" element={<ProductDetails />} />
        </Route>
        <Route element={<PrivateRoute userCheck={user} />}>
        <Route path="/checkout" element={<CheckOut />} />
        </Route>
        <Route element={<AuthProfile userCheck={user} />}>
        <Route path="/profile" element={<Profile />} />
        </Route>
        
      </Routes>
      
    </>
  );
};

const NoData = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  })
  return (<>
  </>)
}
export default AllRoute;
