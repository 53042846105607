import React, { Component, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { CatBasedAllProduct } from "../../../BackendData/FetchApi";
import Paginate from "../../Component/Paginate";

const SimilarProduct = ({ categoryName }) => {
  const [ProductList, setProductList] = useState([]);

  useEffect(() => {
    CatBasedAllProduct(categoryName).then((res) => setProductList(res));
  }, [categoryName]);

  return (
    <>
      <Container>
        <Paginate
          itemsPerPage={4}
          items={ProductList}
          title={"SimilarProduct"}
        />
      </Container>
    </>
  );
};

export default SimilarProduct;
