import React, { Component, useEffect } from "react";
import { Image } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";


const ProductItem = ({
  productId,
  productName,
  productImage,
  productPrice,
  productStock,
  tempPrice,
}) => {
  useEffect(() => {}, [productImage]);

  return (
    <>
  
      <div className="col-md-2 card_wrapper  col-sm-3 mt-4 ">
          <div className="product_item_img">
            <Image
              alt="product-image"
              src={`https://shop.gtshopltd.com/public/images/product/${productImage}`}
            />
          </div>
          <div className="protien">
            <h6 className="product_title">{productName}</h6>
            <h3 className="product_price">{productPrice} Tk</h3>

            {tempPrice === productPrice ? null : (
              <h3 className="product_price text-danger">
                {" "}
                <del>{tempPrice !== "" ? `${tempPrice} tk` : null}</del>
              </h3>
            )}
            <Link className="view_more_btn" to={`/product/${productId}`}>
              Shop Now
            </Link>
          </div>
          <Outlet />

      </div>
       
    </>
   
  );
};
export default ProductItem;
