import React, { Component, useEffect, useState } from "react";
import ModalCmp from "../Modal";

const PendingTransectionCMP = ({ data }) => {
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    setGetData(data);
  }, [data]);

  return (
    <>
      {" "}
      <div className="table-responsive">
        <table
          className="table table-bordered table-striped"
          style={{ width: "70%",textAlign:'center' }}
        >
          <thead className="bg-primary text-light p-3">
            <tr>
            <th>Sl</th>
            <th>Date</th>
            <th>Order Id</th>
            <th>Total Price</th>
              <th>Action</th>
              </tr>
          </thead>
          <tbody>
            {getData &&
              getData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.created_at}</td>
                  <td>{item.order_number}</td>
                  <td>{item.total_amount}</td>

                  <td>
                    <ModalCmp title={'pending'} total={item.total_amount} orderId={item.order_id} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default PendingTransectionCMP;
