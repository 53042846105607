import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getId } from "../../../Redux/Reducer/CategorySlice";
import SideBar from "../../Component/SideBar";
import MySpinner from "../../Component/Spinner";
import ProductItem from "../../Component/ProductItem";
import {
  AllProduct,
  fetchCategory,
  GetProductByCategory,
} from "../../../BackendData/FetchApi.js";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Paginate = lazy(() => import("../../Component/Paginate"));

const AllProductCat = () => {
  const [FetchProduct, setFetchProduct] = useState([]);
  const [Category, setCategory] = useState([]);
  const [FilterId, setFilterId] = useState(sessionStorage.getItem("mPageF"));
  const [FilteredProduct, setFilterProcduct] = useState([]);
  const location = useLocation();
  const IdData = useSelector((state) => state.category.value);
  const dispatch = useDispatch();

  useEffect(() => {
    //let data = sessionStorage.getItem("mPageF");
    // let GetNavId = location.state.id
    console.log(IdData);
    // console.log(GetNavId)

    if (IdData == null) {
      // if (IdData !== null) {
      //   GetProductByCategory(FilterId).then((res) => setFilterProcduct(res));
      //   sessionStorage.removeItem("mPageF");
      // } else {
      AllProduct().then((res) => setFetchProduct(res));
      dispatch(getId(0));

      //}
    } else {
      GetProductByCategory(IdData).then((res) => setFilterProcduct(res));
    }

    fetchCategory().then((res) => setCategory(res));
  }, [FilterId, setFilterProcduct, IdData, dispatch]);

  const handleAllProduct = () => {
    window.location.reload(true);
  };
  return (
    // <>
    //   <div className="m-4">
    //     <Col md={8} lg={10}  className="overflow-auto">
    //       {FilteredProduct.length !== 0 ? (
    //         FilteredProduct.map((i, index) => (
    //           <ProductItem
    //             productId={i.id}
    //             productName={i.name}
    //             productImage={i.image}
    //             productPrice={i.price}
    //             productStock={i.qty}
    //             tempPrice={i.temp_price}
    //           />
    //         ))
    //       ) : (
    //         <div>
    //           {FetchProduct.length !== 0 ? (
    //             <Suspense
    //               fallback={
    //                 <>
    //                   <MySpinner />
    //                 </>
    //               }
    //             >
    //               <Paginate
    //                 title={"AllProduct"}
    //                 itemsPerPage={10}
    //                 items={FetchProduct}
    //               />
    //             </Suspense>
    //           ) : (
    //             <MySpinner />
    //           )}
    //         </div>
    //       )}
    //     </Col>
    //   </div>
    <Box sx={{ flexGrow: 3}}>
    <Grid container spacing={3}>
    <Grid item xs={12} md={12}>
          <Item>
           {FilteredProduct.length !== 0 ? (
            FilteredProduct.map((i, index) => (
              <ProductItem
                productId={i.id}
                productName={i.name}
                productImage={i.image}
                productPrice={i.price}
                productStock={i.qty}
                tempPrice={i.temp_price}
              />
            ))
          ) : (
            <div>
              {FetchProduct.length !== 0 ? (
                <Suspense
                  fallback={
                    <>
                      <MySpinner />
                    </>
                  }
                >
                  <Paginate
                    title={"AllProduct"}
                    itemsPerPage={10}
                    items={FetchProduct}
                  />
                </Suspense>
              ) : (
                <MySpinner />
              )}
            </div>
          )}
          </Item>
        </Grid>
    </Grid>
    </Box>
    
    // </>
    // <Box sx={{ flexGrow: 1 }}>
    //   <Grid
    //     container
    //     spacing={0}
    //     direction="column"
    //     alignItems="center"
    //     justifyContent="center"
    //   >
    //     <Grid item xs={12}>
    //       <Item>

    //         {FilteredProduct.length !== 0 ? (
    //           FilteredProduct.map((i, index) => (
    //             <ProductItem
    //               productId={i.id}
    //               productName={i.name}
    //               productImage={i.image}
    //               productPrice={i.price}
    //               productStock={i.qty}
    //               tempPrice={i.temp_price}
    //             />
    //           ))
    //         ) : (
    //           <div>
    //             {FetchProduct.length !== 0 ? (
    //               <Suspense
    //                 fallback={
    //                   <>
    //                     <MySpinner />
    //                   </>
    //                 }
    //               >
    //                 <Paginate
    //                   title={"AllProduct"}
    //                   itemsPerPage={10}
    //                   items={FetchProduct}
    //                 />
    //               </Suspense>
    //             ) : (
    //               <MySpinner />
    //             )}
    //           </div>
    //         )}
    //       </Item>
    //     </Grid>
    //   </Grid>
    // </Box>
  );
};

const ForNoData = ({ setFetchProduct }) => {
  const handleReload = (e) => {
    window.location.reload();
  };
  return (
    <>
      <h1>No Data available </h1>
      <h1>Please Reload for searching All products</h1>
      <button onClick={() => handleReload(setFetchProduct)}> Reload</button>
    </>
  );
};
export default AllProductCat;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  justifyContent: "center",
}));
