import { createSlice } from "@reduxjs/toolkit";

export const couponSlice = createSlice({
  name: "cartSlice",
  initialState: {
    amount: 0,
  },
  reducers: {
    amountIncrement: (state, action) => {
      state.amount += action.payload;
    },
    amountBlank: (state) => {
      state.amount = 0;
    }
  },
});

// Action creators are generated for each case reducer function
export const { amountIncrement,amountBlank } = couponSlice.actions;

export default couponSlice.reducer;