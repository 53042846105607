import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "cartSlice",
  initialState: {
    value: null,
  },
  reducers: {
    getId: (state, action) => {
      state.value = action.payload;
    },
    delId:(state)=>{
      state.value = null
    },
  },
});

// Action creators are generated for each case reducer function
export const { getId,delId } = categorySlice.actions;

export default categorySlice.reducer;