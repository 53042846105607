import React, { useEffect, useState } from "react";
import "../../style/Paginate.css";
import ReactPaginate from "react-paginate";
import ProductItem from "../Component/ProductItem";
import ApproveTransectionCMP from "./ProfileTabCmp/ApproveTransectionCMP";
import PendingTransectionCMP from "./ProfileTabCmp/PendingTransectionCMP";
import CompleteTransectionCMP from "./ProfileTabCmp/CompletedTransectionCMP";

function Items({ currentItems, title }) {
  switch (title) {
    case "AllProduct":
      return (
        <>
          <div className="row">
            {currentItems &&
              currentItems.map((items, index) => (
                
                <ProductItem
                  key={index}
                  productId={items.id}
                  productName={items.name}
                  productImage={items.image}
                  productPrice={items.price}
                  tempPrice = {items.temp_price                  }
                  productStock={null}
                />
              ))}
          </div>
        </>
      );
    case "ApproveTransection":
      return (
        <>
          <ApproveTransectionCMP data={currentItems} />
        </>
      );
    case "PendingTransection":
      return (
        <>
          <PendingTransectionCMP data={currentItems} />
        </>
      );
    case "CompleteTransection":
      return (
        <>
          <CompleteTransectionCMP data={currentItems} />
        </>
      );
    case "SimilarProduct":
      return (
        <>
          <div className="row">
            {currentItems &&
              currentItems.map((items, index) => (
                
                <ProductItem
                  key={index}
                  productId={items.id}
                  productName={items.name}
                  productImage={items.image}
                  productPrice={items.price}
                  tempPrice = {items.temp_price                  }
                  productStock={null}
                />
              ))}
          </div>
        </>)
      

    default:
      return null;
  }
}

function PaginatedItems({ itemsPerPage, items, title }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items, title]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items title={title} currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
    </>
  );
}

export default PaginatedItems;
