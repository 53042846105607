import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Container, Button } from "react-bootstrap";
import { postCheckout, Charge } from "../../../BackendData/FetchApi";
import AuthContext from "../../../Context/AuthContext";
import CartList from "../../Component/CartList";
import { dataGet } from "../../../Redux/Reducer/CartSlice";
import { amountBlank } from "../../../Redux/Reducer/CouponPrice";


const CheckOut = () => {
  const user = useContext(AuthContext);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [CheckAddr, setCheckAddr] = useState("exist");
  const [changePhnNum, setChangePhnNum] = useState("exist");
  const [NewNumber, setNewNumber] = useState("");
  const [Address, setAddress] = useState("");
  const [User, setUser] = useState("");
  const [CartProduct, setCartProduct] = useState([]);
  const [DelCharge, setDelCharge] = useState(0);
  const [FinalCartList, setFinalCartList] = useState(
    JSON.parse(sessionStorage.getItem("CartProductList"))
  );
  const [checkTotal, setCheckTotal] = useState(0);

  useEffect(() => {
    if (sessionStorage.getItem("CartProductList") !== null) {
      setUser(JSON.parse(sessionStorage.isLogin));
      setCartProduct(JSON.parse(sessionStorage.CartProductList));
      Charge().then((res) => setDelCharge(res.amount));
      setCheckTotal(JSON.parse(sessionStorage.total));
    }
  }, []);

  function checkedConfirm(data) {
    if (data === "success") {
      alert("Your Product pending for Approval");
      dispatch(amountBlank())
      sessionStorage.removeItem("CartProductList");
      dispatch(dataGet(0))
      navigate("/");
    } else {
      alert("Your Product is not submitted please try again");
      sessionStorage.removeItem("CartProductList");
      navigate("/");
    }
  }

  const handleNewForm = async (e) => {
    e.preventDefault();
    let confirm = "";
    let sub_total = 0;
    let delivery_rate = DelCharge;
    let total = 0;
    let productId = [];
    let price = [];
    let qty = [];
    CartProduct.forEach((i) => {
      sub_total += parseFloat(i.price);
      productId.push(i.id);
      price.push(i.price);
      qty.push(i.Qty);
      total = sub_total + delivery_rate;
    });
    let coupon_id = await JSON.parse(sessionStorage.coupon_id);
    if (NewNumber !== "") {
      let a = {
        user_id: User.id,
        address: Address,
        mobile: NewNumber,
        sub_total: sub_total,
        delivery_charge: delivery_rate,
        order_total_amount: total,
        product_id: productId,
        price: price,
        qty: qty,
        coupon_id: coupon_id,
      };
      await postCheckout(a).then((res) => (confirm = res));
      checkedConfirm(confirm);
    } else {
      let coupon_id = await JSON.parse(sessionStorage.coupon_id);
      let a = {
        user_id: User.id,
        address: Address,
        mobile: User.mobile,
        sub_total: sub_total,
        delivery_charge: delivery_rate,
        order_total_amount: total,
        product_id: productId,
        price: price,
        qty: qty,
        coupon_id: coupon_id,
      };

      await postCheckout(a).then((res) => (confirm = res));

      checkedConfirm(confirm);
    }
  };

  const ExistAddrCheckOut = async () => {
    let confirm = "";
    let sub_total = 0;
    let total = 0;
    let productId = [];
    let price = [];
    let qty = [];
    CartProduct.forEach((i) => {
      sub_total += parseFloat(i.price);
      productId.push(i.id);
      price.push(i.price);
      qty.push(i.Qty);
      total = sub_total + DelCharge;
    });
    let coupon_id;
    if (sessionStorage.coupon_id !== undefined) {
      coupon_id = await JSON.parse(sessionStorage.coupon_id);

    } else {
      coupon_id = null;
    }

    let a = {
      user_id: User.id,
      address: User.address,
      mobile: User.mobile,
      sub_total: sub_total,
      delivery_charge: DelCharge,
      order_total_amount: total,
      product_id: productId,
      price: price,
      qty: qty,
      coupon_id: coupon_id,
    };
    await postCheckout(a).then((res) => (confirm = res));
    checkedConfirm(confirm);
  };

  return (
    <>
      <div className="container">
        <div className="py-5 text-center">
          <h2>Checkout form</h2>
        </div>

        <div className="row g-5">
          <div className="col-md-5 order-md-last">
            <CartList
              setProductImg={`https://jmngroupbd.com/public/images/product/`}
              setCartProduct={FinalCartList}
              page={"checkout"}
              TotalPrice={checkTotal}
              DelCharge={DelCharge}
            />
          </div>
          <div className="col-md-7">
            <h4 className="mb-3">Billing address</h4>
            <div className="d-flex">
              <div className="m-2">
                <input
                  type="radio"
                  name="address"
                  value="new"
                  onChange={() => setCheckAddr("new")}
                />

                <label htmlFor="address"> New Address</label>
              </div>
              <div className="m-2">
                <input
                  type="radio"
                  name="address"
                  value="exist"
                  onChange={() => setCheckAddr("exist")}
                  checked={CheckAddr === "exist" ? true : false}
                  required
                />
                <label htmlFor="address"> Existing Address</label>
              </div>
            </div>
            {CheckAddr === "new" ? (
              <div>
                <form onSubmit={handleNewForm}>
                  <div className="m-2">
                    <input
                      type="text"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Input your current address"
                    />
                  </div>
                  <div className="d-flex">
                    <div className="m-2">
                      <input
                        type="radio"
                        name="number"
                        value="new"
                        onChange={() => setChangePhnNum("new")}
                      />
                      <label htmlFor="number"> New Number</label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="number"
                        value="exist"
                        onChange={() => setChangePhnNum("exist")}
                        checked={changePhnNum === "exist" ? true : false}
                      />
                      <label htmlFor="number"> Existing Number</label>
                    </div>
                  </div>
                </form>
                {changePhnNum === "new" ? (
                  <div className="m-2">
                    <input
                      type="number"
                      value={NewNumber}
                      onChange={(e) => setNewNumber(e.target.value)}
                      placeholder="New Number"
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="row g-3">
                <div className="col-sm-6">
                  <label htmlFor="firstName" className="formLabel">
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={User.name}
                    readOnly
                  />
                </div>

                <div className="col-sm-6">
                  <label htmlFor="lastName" className="formLabel">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={User.mobile}
                    readOnly
                  />
                </div>
              </div>
            )}

            <hr className="my-4" />

            <h4 className="mb-3">Payment</h4>

            <div className="my-3">
              <div>
                <input
                  id="credit"
                  name="paymentMethod"
                  type="radio"
                  defaultChecked
                  required
                />
                <label className="form-check-label" htmlFor="credit">
                  Cash on delivery
                </label>
              </div>
            </div>

            <hr className="my-4" />
            <div>
              {CheckAddr === "exist" ? (
                <Button onClick={ExistAddrCheckOut}>Confirm</Button>
              ) : (
                <Button onClick={handleNewForm}>Comfirm</Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckOut;
