import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { AllWearHouse } from "../../../BackendData/FetchApi";
const Footer = () => {
  const [AllWearHouseList, setAllWearHouseList] = useState([]);
  useEffect(() => {
    AllWearHouse().then((res) => setAllWearHouseList(res));
  }, []);

  return (
    <>
      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a
              href="https://www.facebook.com/gtcshopltd"
              className="me-4 text-reset"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-google"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h4 className="text-uppercase fw-bold mb-4 text-success">
                  GT Shop LTD
                </h4>
                <h5 className="text-uppercase fw-bold mb-4 text-primary">
                  <a
                    href="http://shop.gtshopltd.com/login"
                    className="text-reset"
                  >
                    User Login
                  </a>
                </h5>
                <h5 className="text-uppercase fw-bold mb-4 text-primary">
                  <a
                    href="https://mega.nz/folder/eKpjGKDA#wXPO5GhG_EiKT5os12Y_mg"
                    className="text-reset"
                  >
                    Officer Apps
                  </a>
                </h5>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Branch</h6>

                {AllWearHouseList &&
                  AllWearHouseList.map((items, index) => (
                    <li key={index}>
                      <OverlayTrigger
                        key={index}
                        placement={"right"}
                        overlay={
                          <Tooltip id={`tooltip-${items.address}`}>
                            <strong>{items.address}</strong>.
                          </Tooltip>
                        }
                      >
                        <p key={index}>
                          <a href="#!" className="text-reset">
                            {items.name}
                          </a>
                        </p>
                      </OverlayTrigger>
                    </li>
                  ))}
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="#!" className="text-reset">
                    GT E-Shop
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    GT Assembler
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    GT Live
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-home me-3"></i> Shatmatha, Rangpur
                </p>
                <p>
                  <i className="fas fa-envelope me-3"></i>
                  gtcshopltd@gmail.com
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> 01756987252
                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};
export default Footer;
