import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataProvider from "../../Context/DataProvider";
import {
  DeletePendingOrder,
  OrderDetailsBaseId,
} from "../../BackendData/FetchApi";

function ModalCmp({ orderId, title,total }) {
  const [show, setShow] = useState(false);
  const [ActionOrder, setActionOrder] = useState([]);

  useEffect(() => {
    OrderDetailsBaseId(orderId).then((res) => setActionOrder(res));
  }, [orderId]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = (data) => {
    var answer = window.confirm("Are you sure for delete this transection?");
    if (answer) {
      DeletePendingOrder(data).then((res) => {
        if (res === "Order deleted successfully") {
          alert(res);
          window.location.reload(true);
        }
      });
    } else {
      console.log(answer);
    }
  };

  return (
    <>
      <Button variant="info" onClick={handleShow}>
        View
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered" >
            <thead>
              <tr>
                <th >Sl</th>
                <th>Product</th>
                <th>Qty</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {ActionOrder.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.qty}</td>
                  <td>{item.price}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>Total Price</td>
                <td>{total}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {title === "pending" ? (
            <Button variant="danger" onClick={() => handleDelete(orderId)}>
              Delete
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCmp;
