import { configureStore,combineReducers } from '@reduxjs/toolkit'
import CartSlice from './Reducer/CartSlice'
import CouponPrice from './Reducer/CouponPrice'
import CategorySlice from './Reducer/CategorySlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'

import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({ 
  cart: CartSlice,
  coupon: CouponPrice,
  category:CategorySlice

})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer ,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)