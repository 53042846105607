import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const CheckLogin = ({ userCheck }) => {
  let CheckCartProduct = JSON.parse(sessionStorage.getItem("CartProductList"));
  console.log(CheckCartProduct);
  if (userCheck === true) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default CheckLogin;
