import React,{useEffect,useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import {AllProduct} from '../../BackendData/FetchApi'

export default function ComboBox() {
  const navigate = useNavigate()
  const [items, setItems] = useState(null)
  useEffect(() => {
    let data=[]
    AllProduct().then((res)=>{
      res.map((item)=>{
        data.push({label:item.name,id:item.id})
      })
      setItems(data)
    })

  },[items])

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={items}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Products" />}
      onChange={(event, newValue) => {
        
          navigate(`/product/${newValue.id}`)
       
        
      }}
    />
  );
}

