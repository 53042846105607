import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { RegisterUser, AllUserName } from "../../../../BackendData/FetchApi";
import { Card, Button, Alert } from "react-bootstrap";

export default function Register() {
  const navigate = useNavigate();
  const [UserName, setUserName] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    AllUserName().then((res) => setUserName(res));
    AllUserName().then((res) => console.log(res));
  }, []);

  const onSubmit = (data) => {
    if (data !== null) {
      if (data.password === data.cPassword) {
        let MakeData = {
          name: data.name,
          username: data.username,
          password: data.password,
          address: data.address,
          email: data.mail,
          mobile: data.mobile_number,
        };

        RegisterUser(MakeData).then((res) => {
          console.log(res);
          if (res !== undefined) {
            if (res.username === data.username) {
              alert("Successfully Register");
              navigate("/login");
            } else {
              alert("This Mobile number or username already exist ");
            }
          } else {
            alert("This Mobile number or username already exist ");
          }
        });
      } else {
        alert("Password Not Matching");
      }
    } else {
      alert("User Name Exist");
    }
  };

  return (
    <>
      <div className="login_wrapper">
        <Card style={{ width: "28rem" }}>
          <Card.Header>Register</Card.Header>
          <Card.Body>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mb-3">
                    <label htmlFor="name">Your Name</label>

                    <input
                      type="text"
                      {...register("name", { required: true })}
                      aria-invalid={errors.firstName ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.name?.type === "required" && (
                      <p className="text-danger" role="alert"> name is required</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Username </label>
                    <input
                      {...register("username", { required: true })}
                      aria-invalid={errors.username ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.username?.type === "required" && (
                      <p className="text-danger" role="alert">User Name  is required</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      {...register("mobile_number", {
                        required: true,
                        pattern: /^([+]\d{2})?\d{11}$/,
                      })}
                      aria-invalid={errors.mobile_number ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.mobile_number?.type === "required" && (
                      <p className="text-danger" role="alert">Mobile number name is required</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Email (Optional)</label>

                    <input
                      type="email"
                      {...register("mail", { required: false })}
                      aria-invalid={errors.mail ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.password?.type === "required" && (
                      <p role="alert">Password is required</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}
                      aria-invalid={errors.password ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.password?.type === "required" && (
                      <p className="text-danger" role="alert">Password is required</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="password"
                      {...register("cPassword", {
                        required: true,
                      })}
                      aria-invalid={errors.cPassword ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.cPassword && (
                      <p className="text-danger" role="alert">{errors.cPassword?.message}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Address</label>
                    <input
                      {...register("address", { required: true })}
                      aria-invalid={errors.address ? "true" : "false"}
                      className="form-control"
                    />
                    {errors.address?.type === "required" && (
                      <p className="text-danger" role="alert">address is required</p>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn form-control btn-sm btn-primary"
                  >
                    Register
                  </button>
                </div>
                <div className="mt-3">
                  <span>
                    You have account ?
                    <Link
                      to="/login"
                      className="ml-2 form-control btn btn-sm btn-success"
                    >
                      Login
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
