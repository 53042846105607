import axios from "axios";
const access_token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTU2ODQ1NDQ5NyIsIm5hbWUiOiJyaXJyYWtpYiIsImlhdCI6MTUxNjIzOTAyMn0.co3ShLW6MjtvJbRFAkLc-eibSQ0X73R4KU19Ie2oXbM";

//Login Func

export async function postLogin(value) {
  let url = "https://shop.gtshopltd.com/api/login";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function fetchCategory() {
  let url = "https://shop.gtshopltd.com/api/category";
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      // console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function featuresProduct() {
  let url = "	https://shop.gtshopltd.com/api/features";
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      // console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

//Get Product details

export async function GetProductDetails(id) {
  let url = `https://shop.gtshopltd.com/api/products/${id}`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //   console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

//get Product category based

export async function GetProductByCategory(id) {
  let url = `https://shop.gtshopltd.com/api/category/${id}`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //console.log(res.data,'abcd')
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

//get product based on letter
export async function GetProductByLetter(keyword) {
  let url = `https://shop.gtshopltd.com/api/products/search/${keyword}`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //   console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function SliderApi(keyword) {
  let url = `https://shop.gtshopltd.com/api/slider`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function AllProduct() {
  let url = "	https://shop.gtshopltd.com/api/products";
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function postCheckout(value) {
  let url = "https://shop.gtshopltd.com/api/checkout";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //   console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function Charge() {
  let url = `https://shop.gtshopltd.com/api/charge`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      // console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function Coupon(value) {
  let url = "https://shop.gtshopltd.com/api/coupen";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //   console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function AllUserName() {
  let url = `https://shop.gtshopltd.com/api/user`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function RegisterUser(value) {
  let url = "https://shop.gtshopltd.com/api/register";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      // console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function OrderInfo() {
  let url = `https://shop.gtshopltd.com/api/orderinfo`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      // console.log(res)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function OrderInfoId(id) {
  let dataPending = await OrderInfo().then((res) => {
    let getData = [];
    getData = res
      .filter((item) => id === item.user_id)
      .filter((item) => item.pro_status === 0);
    console.log(getData);

    var map = new Map(getData.map((o) => [o.order_id, o]));
    var uniquePending = [...map.values()];

    const groupProductByOrderIdPending = getData.reduce((group, product) => {
      const { order_id } = product;
      group[order_id] = group[order_id] ?? [];
      group[order_id].push(product.name, product.product_quantity);
      return group;
    }, {});

    let a = {
      getData,
      uniquePending,
      groupProductByOrderIdPending,
    };

    return a;
  });
  let dataApprove = await OrderInfo().then((res) => {
    let getData = [];
    getData = res
      .filter((item) => id === item.user_id)
      .filter((item) => item.pro_status === 1);
    console.log(getData);

    var map = new Map(getData.map((o) => [o.order_id, o]));
    var uniqueApprove = [...map.values()];

    const groupProductByOrderIdApprove = getData.reduce((group, product) => {
      const { order_id } = product;
      group[order_id] = group[order_id] ?? [];
      group[order_id].push(product.name, product.product_quantity);
      return group;
    }, {});
    let a = {
      getData,
      uniqueApprove,
      groupProductByOrderIdApprove,
    };
    console.log(a);

    return a;
  });

  let dataComplete = await OrderInfo().then((res) => {
    let getData = [];
    getData = res
      .filter((item) => id === item.user_id)
      .filter((item) => item.pro_status === 2);

    console.log(getData);
    var map = new Map(getData.map((o) => [o.order_id, o]));
    var uniqueComplete = [...map.values()];

    const groupProductByOrderIdComplete = getData.reduce((group, product) => {
      const { order_id } = product;
      group[order_id] = group[order_id] ?? [];
      group[order_id].push(product.name, product.product_quantity);
      return group;
    }, {});
    let a = {
      getData,
      uniqueComplete,
      groupProductByOrderIdComplete,
    };

    return a;
  });
  return { dataPending, dataApprove, dataComplete };
}

//Delete pending transection

export async function DeletePendingOrder(id) {
  let url = `https://shop.gtshopltd.com/api/order-delete/${id}`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      // console.log(res)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function HotSale() {
  let url = `https://shop.gtshopltd.com/api/top-sale`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function UserEdit(value) {
  let url = "https://shop.gtshopltd.com/api/user-edit";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function OrderDetailsBaseId(id) {
  let url = `https://shop.gtshopltd.com/api/order-details/${id}`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => {
      //console.log(error);
    });
}

export async function CatBasedAllProduct(name) {
 
 const b = await  fetchCategory().then((res) => {
    const GetCat = res.find((res) => res.name === name);
    const a =  GetProductByCategory(GetCat.id).then((res) => res);
    return a
 });
  return b
}


export async function SendOtp(value) {
  let url = "https://shop.gtshopltd.com/api/send-otp";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function ConfirmOtp(value) {
  let url = "https://shop.gtshopltd.com/api/confirm-otp";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function ResetPassword(value) {
  let url = "https://shop.gtshopltd.com/api/reset-password";
  return await axios
    .post(url, value, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
      //  console.log(res.data)
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function AllWearHouse() {
  let url = `https://shop.gtshopltd.com/api/warehouse`;
  return await axios
    .get(url, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      authCredintial: true,
      headers: {
        Authorization: `${access_token}`,
      },
    })
    .then((res) => {
       
      return res.data;
    })
    .catch((error) => {
      //console.log(error);
    });
}