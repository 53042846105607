import React, { Component, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { red, green, blue } from '@mui/material/colors';
import "./style/owl.carousel.css";
import "./style/style.css";
import "./style/responsive.css";
import NavBar from "./Pages/Layout/FixedArea/NavBar";
import PublicRoute from "../src/Router/Route";
import AuthContext from "./Context/AuthContext";
import { dataGet } from "./Redux/Reducer/CartSlice";
import { amountIncrement, amountBlank } from "./Redux/Reducer/CouponPrice";
import NewNavBar from "./Pages/Layout/FixedArea/NewNavBar";
import FooterDiv from "./Pages/Layout/FixedArea/Footer";
import { Breadcrumb, Layout, Menu, theme, Affix } from "antd";
const { Header, Content, Footer } = Layout;

const MainPage = () => {
  const dispatch = useDispatch();
  const x = useSelector((state) => state.cart.value);
  const [user, setUser] = useState(null);
  const [check, setCheck] = useState(sessionStorage.getItem("isLogin"));

  useEffect(() => {
    if (check === null) {
      setUser(false);
      if (x !== 0) {
        dispatch(dataGet(0));
        dispatch(amountBlank());
      }
    } else {
      setUser(true);
    }
  }, [check, x, dispatch]);
  return (
    <AuthContext.Provider value={user}>
      <Root>
      <Layout className="layout">
        <Header style={style}>
          <Affix offsetTop>
            <NewNavBar />
          </Affix>
        </Header>
        <Content
          style={{
            padding: "10px",
          }}
        >
          <PublicRoute />
        </Content>
      </Layout>
      </Root>
    </AuthContext.Provider>
  );
};
export default MainPage;

const style = {
  background: "#0092ff",
  padding: "0",
};

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {

    overflow:'auto',
    margin:'0px 50px 50px 50px'
  },
  [theme.breakpoints.up('md')]: {
    overflow:'auto',
    margin:'0px 50px 50px 50px'
  },
  [theme.breakpoints.up('lg')]: {
    margin:'0px 50px 50px 50px'
  },
}));