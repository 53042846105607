import React, { useEffect, useState } from "react";
import { SliderApi } from "../../BackendData/FetchApi";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { styled } from '@mui/material/styles';
import { red, green, blue } from '@mui/material/colors';
//import '../../style/slider.css'
var Carousel = require("react-responsive-carousel").Carousel;



const Slider = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    SliderApi().then((res) => setData(res));
  }, []);

  return (
<Root>
    <Carousel
      autoPlay={true}
      showThumbs={false}
      // centerMode={true}
      infiniteLoop={true}
      interval={2000}
      // centerSlidePercentage={60}
    >
      {data &&
        data.map((i, index) => (
          <div key={index}>
            <img            
              className="w-50 h-50"
              src={`https://shop.gtshopltd.com/public/images/slider/${i.image}`}
              alt="First slide"
            />
          </div>
        ))}
    </Carousel>
    </Root>
  );
};

export default Slider;

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginTop:80,
  },
  [theme.breakpoints.up('md')]: {
    marginTop:40,
  },
  [theme.breakpoints.up('lg')]: {
  },
}));