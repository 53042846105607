import React, { Component, useEffect, useState } from "react";
import { featuresProduct } from "../../../BackendData/FetchApi";
import ProductItem from "../../Component/ProductItem";

const FeaturesProduct = () => {
      const [getFeaturesProduct, setFeaturesProduct] = useState(null);
      useEffect(() => {
        featuresProduct().then((res) => setFeaturesProduct(res));
        
  }, []);
  return (
    <>
      <div className="container">
        <h2 className="text-center mt-1 mb-3">Featured Products</h2>
        <div className="row justify-content-center">
          
          {getFeaturesProduct &&
            getFeaturesProduct.map((items, index) => (
              <ProductItem
                key={index}
                        productId={items.id}
                        productName={items.name}
                        productImage={items.image}
                        productPrice={items.price}
                productStock={null}
                tempPrice = {items.temp_price}
              />
            ))}
        </div>
      </div>
    </>
  );
};
export default FeaturesProduct;
