import React, { lazy, useState, Suspense, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MySpinner from "../Component/Spinner";
const ApproveTrans = lazy(() =>
  import("./ProfileTabCmp/ApproveTransection")
);
const PendingTrans = lazy(() =>
  import("../Component/ProfileTabCmp/PendingTransection")
);
const CompletedTrans = lazy(() =>
  import("../Component/ProfileTabCmp/CompletedTransection")
);

function TransectionTab() {
  const [key, setKey] = useState("pending");

  useEffect(() => {}, []);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
       <Tab eventKey="pending" title="Pending transection">
        <Suspense fallback={<MySpinner />}>
          <PendingTrans />
        </Suspense>
      </Tab>
      <Tab eventKey="total" title="Approve transection">
        <Suspense fallback={<MySpinner />}>
          <ApproveTrans />
        </Suspense>
      </Tab>
     
      <Tab eventKey="complete" title="Complete transection">
        <Suspense fallback={<MySpinner />}>
          <CompletedTrans />
        </Suspense>
      </Tab>
    </Tabs>
  );
}

export default TransectionTab;
