import logo from "./logo.svg";
import { NavLink, Link } from "react-router-dom";
import "./App.css";
import MainPage from "./MainPage";
import "./style/custom.css"


 import { ProSidebarProvider } from "react-pro-sidebar";

function App() {
  return (
    <div className="App">
      <ProSidebarProvider>
        <MainPage />
      </ProSidebarProvider>
    </div>
  );
}

export default App;
