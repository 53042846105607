import React, { Component, useEffect, useState, useContext } from "react";
import { Container, Card, Button, Badge, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AuthContext from "../../Context/AuthContext";
import { Charge, Coupon } from "../../BackendData/FetchApi";
import { dataGet } from "../../Redux/Reducer/CartSlice";
import {amountIncrement,amountBlank} from '../../Redux/Reducer/CouponPrice'

const CartList = ({
  setProductImg,
  setCartProduct,
  page,
  DelCharge,
  TotalPrice,
}) => {
  const [product, setProduct] = useState([]);
  const [DeliveryCharge, setDeliveryCharge] = useState(null);
  const [CouponCode, setCouponCode] = useState("");
  const [CouponTaka, setCouponTaka] = useState(0);
  const dispatch = useDispatch();
  const StateCouponTaka = useSelector((state)=>state.coupon.amount)

  const navigate = useNavigate();
  const user = useContext(AuthContext);
  const data = JSON.parse(sessionStorage.getItem("CartProductList"));
  useEffect(() => {
    
    setCouponTaka(StateCouponTaka)


    if (setCartProduct !== null) {
      setProduct(setCartProduct);
      dispatch(dataGet(setCartProduct.length));
      Charge().then((res) => setDeliveryCharge(parseFloat(res.amount)));
    }
  }, [setCartProduct, dispatch,StateCouponTaka]);

  const handleCheckout = (product) => {
    console.log(product);
    let getTotal = 0;
    let finalTotal = 0;
    if (product !== null) {
      product.forEach((i) => (getTotal += parseFloat(i.price)));

      finalTotal = DeliveryCharge + getTotal;

      sessionStorage.setItem("total", JSON.stringify(finalTotal));
      navigate("/checkout");
    } else {
      alert("no data available");
    }
  };

  const handleCoupon = (CouponCode) => {
    let id = JSON.parse(sessionStorage.isLogin);
    let value = {
      code: CouponCode,
      user_id: id.id,
    };

    if (value.CouponCode !== "") {
      Coupon(value).then((res) => {
        // setCouponTaka(res.amount);

        dispatch(amountIncrement(res.amount))
        let percentageMake = res.amount;
        let CouponList = [];
        let trace = [];
        let abc = res.id;

        function makePercent(a) {
          console.log(a);
          let GetData = JSON.parse(sessionStorage.total);
          let x = 0;
          x = parseFloat(a);

          if (x !== 0) {
            async function makeFind() {
              let b = await sessionStorage.coupon_id;

              //checking coupon id if available

              if (b !== undefined) {
                CouponList = await JSON.parse(sessionStorage.coupon_id);

                return CouponList;
              } else {
                return null;
              }
            }

            async function Checker(CouponList) {
              if (CouponList !== null) {
                let a = await CouponList.find((i) => i == abc);

                if (a !== null) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }

            makeFind().then((res) =>
              Checker(res).then((res) => {
                if (res === true) {
                  GetData = Math.round(GetData - a);
                  sessionStorage.setItem("total", JSON.stringify(GetData));
                  trace = CouponList;
                  trace.push(abc);
                  sessionStorage.setItem("coupon_id", JSON.stringify(trace));
                  window.location.reload(true);
                } else {
                  alert("You already used it");
                }
              })
            );
          } else {
            alert("Not Valid");
          }
        }
        if (res !== false) {
          makePercent(percentageMake);
        } else {
          alert("Not Valid");
        }
      });
    } else {
      alert("No Coupon available");
    }
  };

  const handleDeleteCartProduct = (index) => {
    product.pop(index);
    sessionStorage.setItem("CartProductList", JSON.stringify(product));
    window.location.reload();
  };

  return (
    <>
      <div className="card p-1 shadow">
        <div className="card-header">
          <h6 className="header-title">
            Cart List{" "}
            <span
              className="float-end"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {product !== null || product !== undefined ? (
                <span> {product.length} </span>
              ) : null}
            </span>
          </h6>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-striped">
            <thead className="bg-success text-light">
              <tr>
                <th className="p-2">Sl</th>
                <th className="p-2" colSpan={2}>
                  Product
                </th>

                <th className="p-2">Qty</th>
                <th className="p-2">total</th>
                {page === "checkout" ? null : <th className="p-2">Delete</th>}
              </tr>
            </thead>
            <tbody>
              {product !== null
                ? product.map((items, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td colSpan={2}>{items.name}</td>

                      <td>{items.Qty}</td>
                      <td>{items.price}</td>
                      {page === "checkout" ? null : (
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm me-1 mb-2"
                            data-mdb-toggle="tooltip"
                            title="Remove item"
                            onClick={() => handleDeleteCartProduct(index)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                : null}

              {page === "checkout" ? (
                <tr>
                  <td colSpan={4}> Total</td>
                  <td>{TotalPrice - DelCharge}</td>
                </tr>
              ) : null}
              {page === "checkout" ? (<>
                {CouponTaka!== 0?
                <tr>
                  <td colSpan={4}>Coupon Voutcher</td>
                  <td>(-) {CouponTaka}</td>
                </tr>
               :null} </>) : null}
            </tbody>
            {page === "checkout" ? (
              <tfoot>
                <tr>
                  <td colSpan={4}>Delivery Charge</td>
                  <td colSpan={2}>{DelCharge}</td>
                </tr>
                <tr>
                  <td colSpan={4}>Total Amount</td>
                  <td colSpan={2}>{TotalPrice}</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <input
                      type="text"
                      placeholder="Coupon Code"
                      value={CouponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="form-control"
                    />
                  </td>
                  <td colSpan={2}>
                    <button
                      className="btn btn-success"
                      onClick={() => handleCoupon(CouponCode)}
                    >
                      Redeem
                    </button>
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </table>

          {page === "checkout" ? null : (
            <Button
              className="w-100 btn btn-primary btn-lg"
              onClick={() => handleCheckout(product)}
            >
              Continue to checkout
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CartList;
