import React, { useEffect, useState } from "react";
import Compressor from "compressorjs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Image } from "react-bootstrap";
import { EditOutlined } from "@ant-design/icons";
import { UserEdit, ResetPassword } from "../../BackendData/FetchApi";

function ModalCMPProfile({ userDetails }) {
  const [show, setShow] = useState(false);
  const [UserId, setUserId] = useState("");
  const [UserName, setUserName] = useState("");
  const [Address, setAddress] = useState("");
  const [Mail, setMail] = useState("");
  const [UserImage, setUserImage] = useState(null);
  const [UserMobile, setUserMobile] = useState(null);
  const [Password, setPassword] = useState(null);
  const [ConfirmPassword, setConfirmPassword] = useState(null);
  const [File, setFile] = useState(null);

  useEffect(() => {
    setUserId(userDetails.id);
    setUserName(userDetails.name);
    setAddress(userDetails.address);
    setMail(userDetails.email);
    setUserMobile(userDetails.mobile);

    setFile(
      `https://jmngroupbd.com/public/images/users/${userDetails.user_image}`
    );
  }, [userDetails]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handelSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    if (UserImage !== null) {
      formData.append("user_image", UserImage);
    }

    formData.append("user_id", UserId);
    formData.append("name", UserName);
    formData.append("address", Address);

    UserEdit(formData).then((res) => {
      if (res !== undefined && res !== null) {
        sessionStorage.setItem("isLogin", JSON.stringify(res));
        window.location.reload(true);
      }
    });
  };
  const onChangePicture = (e) => {
    const file = e.target.files[0];
    if (file.size <= 500000) {
      setUserImage(file);
      setFile(URL.createObjectURL(file));
    } else {
      alert("Please upload less than 500000 byte or 500 kb");
    }
  };

  const handleResetPassword = () => {
    if (Password !== null && ConfirmPassword !== null) {
      if (Password === ConfirmPassword) {
        let MakePass = {
          mobile: UserMobile,
          password: Password,
        };
        ResetPassword(MakePass).then((res) => {
          if (res === true) {
            alert("Successfully change the password");
            setPassword(null);
            setConfirmPassword(null);
          }
        });
      } else {
        alert("Password & Confirm password are not same");
      }
    } else {
      alert("Password or Confirm password shouldn't be blank");
    }
  };

  return (
    <>
      <Button
        variant="success"
        onClick={handleShow}
        style={{ margin: 10, padding: 2 }}
      >
        Edit Profile <EditOutlined />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handelSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={UserName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="address"
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/png, image/jpeg"
                onChange={onChangePicture}
              />
              <div>
                <Image
                  style={{ width: 250, height: 250, textAlign: "center" }}
                  className="playerProfilePic_home_tile"
                  src={File && File}
                />
              </div>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={Mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
          <hr />
          <>
            <input
              className="m-2"
              id="password"
              type="password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
            />
            <input
              className="m-2"
              id="Cpassword"
              type="password"
              value={ConfirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
            />
            <Button className="m-2" onClick={handleResetPassword}>
              Confirm
            </Button>{" "}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCMPProfile;
