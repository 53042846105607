import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ShoppingCartOutlined , HomeOutlined,CompressOutlined,DeploymentUnitOutlined,LoginOutlined,LogoutOutlined,ProfileOutlined } from "@ant-design/icons";
import { Container, Nav, Navbar, Form, Dropdown } from "react-bootstrap";
import AuthContext from "../../../Context/AuthContext";
import AutoCmpBox from "../../Component/AutoCompleteBox";
import { dataGet } from "../../../Redux/Reducer/CartSlice";
import { amountBlank } from "../../../Redux/Reducer/CouponPrice";
import NewAutoCmpBox from "../../Component/NewAutoCmpBox";

import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { fetchCategory } from "../../../BackendData/FetchApi";
import { Menu, Layout, Col, Divider, Row, Affix } from "antd";
import { getId, delId } from "../../../Redux/Reducer/CategorySlice";
const { Header, Content, Footer } = Layout;

const NewNavBar = () => {
  const [current, setCurrent] = useState("mail");
  const user = useContext(AuthContext);
  const count = useSelector((state) => state.cart.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Name, setName] = useState("");
  const [Category, setCategory] = useState();
  const location = useLocation();
  const [Auth, setAuth] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  const [MakeCount,setMakeCount] = useState(true)
  

  useEffect(() => {
    let abc = [];
    if (sessionStorage.isLogin !== undefined) {
      let Data = JSON.parse(sessionStorage.isLogin);
      setName(Data.username);
      setIsProfile(false)
    }else{
      setName('Profile');
      setIsProfile(true)
    }
    fetchCategory().then((res) => {
      res.map((item) => {
        abc.push({ label: item.name, key: item.id });
      });

      setCategory(abc);
      // console.log(res)
    });

    if (user === false) {
      setAuth("Login");
      
    } else {
      setAuth("Logout");
     
    }

    if(count===0){
      setMakeCount(true)
    }else{
      setMakeCount(false)
    }
  }, [count, user]);

  const AuthCmp = ()=>{
    if(user === false){
      return   <LoginOutlined />
    }else{
      return  <LogoutOutlined />

    }
    
  }


  const items = [
    {
      label: "Home",
      key: "home",
      icon: <HomeOutlined />,
    },
    {
      label: "Products",
      key: "product",
      icon: <CompressOutlined />,
    },
    {
      label: "Category",
      key: "SubMenu",
      icon: <DeploymentUnitOutlined />,
      children: Category,
    },
    {
      label: Auth,
      key: "auth",
      icon: <AuthCmp/>,
    },
    {
      label: Name,
      key: "profile",
      icon: <ProfileOutlined/>,
      disabled: isProfile,
    },
    {
      label: count,
      key: "countCheck",
      icon:  <ShoppingCartOutlined />,
      disabled: MakeCount,
    },
    {
      label: (
        <a href="https://company.gtshopltd.com/about" target="_blank"  rel="noopener noreferrer">
          About
        </a>
      ),
      key: 'alipay1',
    },
    {
      label: (
        <a href="https://company.gtshopltd.com/our_project" target="_blank" rel="noopener noreferrer">
          Our Project
        </a>
      ),
      key: 'alipay2',
    },
    {
      label: (
        <a href="https://company.gtshopltd.com/our_team" target="_blank" rel="noopener noreferrer">
          Our Team
        </a>
      ),
      key: 'alipay3',
    },
    {
      label: (
        <a href="https://company.gtshopltd.com/career" target="_blank" rel="noopener noreferrer">
          Career
        </a>
      ),
      key: 'alipay4',
    },
    {
      label: (
        <a href="https://company.gtshopltd.com/contact" target="_blank"  rel="noopener noreferrer">
          Contact Us
        </a>
      ),
      key: 'alipay5',
    },

  ];


  const onClick = (e) => {
    console.log("click ", e.keyPath.length);
    
    let pathName = location.pathname; 
    switch (e.keyPath.length) {
      case 1:
        switch (e.key) {
          case "product":
            dispatch(delId());
            navigate("/all_product");
            setCurrent(e.key);
            break;
          case "home":
            setCurrent(e.key);
            navigate("/");
            break;
          case "profile":
            setCurrent(e.key);
            navigate("/profile");
            break;
          case "auth":
            if (user === false) {
              setCurrent(e.key);
              navigate("/login");
            } else {
              sessionStorage.clear();
              dispatch(dataGet(0));
              dispatch(amountBlank());
              navigate("/");
              dispatch(delId());
              window.location.reload();
              setCurrent(e.key);
            }
            navigate("/profile");
            setCurrent(e.key);
            break;
          case 'countCheck':
            setCurrent(e.key);
            navigate('/checkout')
            break
          default:
            break;
        }
        break;
      case 2:
        if (pathName !== "/all_product") {
          sessionStorage.setItem("mPageF", JSON.stringify(e.key));
          dispatch(getId(e.key));
          navigate("/all_product");
          setCurrent(e.key);
        } else if (pathName === "/all_product") {
          dispatch(getId(e.key));
          setCurrent(e.key);
        }
        break;
      default:
        break;
    }
  };
  return (
    <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
      justify="space-around" align="middle"
      style={style}
    >
      <Col className="gutter-row" lg={4}>
        <h1>
          <b>GT SHOP</b>
        </h1>
      </Col>
      <Col  align="middle"lg={4} >
      <NewAutoCmpBox />
      </Col>
      <Col className="gutter-row" lg={16}>
     
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          subMe
        />{" "}
       
      </Col>
    </Row>
  );
};
export default NewNavBar;

const style = {
  background: "#ffffff",
  padding: "0",
};


const sharedStyle = { flex: "0 0 50px", height: 20, background: "red" };