import React, { Component, useEffect, useState } from "react";
import { HotSale,AllProduct } from "../../BackendData/FetchApi";
import ProductItem from "./ProductItem";
import ProductCarousel from "../Layout/Pages/ProductCarousel";

const SingleProduct = ({ title }) => {
  const [HotProductList, setHotProductList] = useState([]);
  const [AllProductList, setAllProductList] = useState([]);
  useEffect(() => {
    
    HotSale().then((res) => setHotProductList(res));
    AllProduct().then((res)=>  setAllProductList(res))
  }, []);

  switch (title) {
    case "hotProduct":
      return (
        <>
          {HotProductList.length !== 0 ? (
            <div id="protien" className="protien_main">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="titlepage">
                      <h2 className="text-center mt-2 mb-2">Hot Products</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <ProductCarousel title={'hotProduct'} />
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
      case "discountProduct":
        return (
          <>
            {AllProductList.length !== 0 ? (
              <div id="protien" className="protien_main">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="titlepage">
                        <h2 className="text-center mt-2 mb-2">Discount Products</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <ProductCarousel title={'discountProduct'} />
                  </div>
                </div>
              </div>
            ) : null}
          </>
        );

    default:
      break;
  }
};
export default SingleProduct;
