import React, { Component, useEffect } from "react";
import { Image } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

const HotProductItem = ({
  productId,
  productName,
  productImage,
  productPrice,
  productStock,
  tempPrice
}) => {
  useEffect(() => {
   
  }, []);


  return (
    <>
      <div className="col-md-8 card_wrapper  col-sm-6 mt-4">
        <div className="product_item_card">
          <div className="product_featured_title">
          
          </div>
          <div className="product_item_img">
            <Image
              src={`https://shop.gtshopltd.com/public/images/product/${productImage}`}
              alt="product-image"
            />
          </div>
          <div className="protien">
            <h6 className="product_title">{productName}</h6>
            <h3 className="product_price">{productPrice} Tk</h3>
            <h3 className="product_price text-danger"> <del>{tempPrice} Tk</del></h3>
            <Link className="view_more_btn" to={`/product/${productId}`}>
              Shop Now
            </Link>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default HotProductItem;
