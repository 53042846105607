import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const AuthProfile = ({ userCheck }) => {
  if (userCheck === false) {
    return <Navigate to="/login" replace />;
  } else {
        return   <Outlet />;
      } 
    
};

export default AuthProfile;
