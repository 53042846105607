import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Container, Nav, Navbar, Form, Dropdown } from "react-bootstrap";
import AuthContext from "../../../Context/AuthContext";
import AutoCmpBox from "../../Component/AutoCompleteBox";
import { dataGet } from "../../../Redux/Reducer/CartSlice";
import { amountBlank } from "../../../Redux/Reducer/CouponPrice";

const NavBar = () => {
  const user = useContext(AuthContext);
  const count = useSelector((state) => state.cart.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Name, setName] = useState("");
  //const [CountCartList, setCountCartList] = useState(count);
  useEffect(() => {
    if (sessionStorage.isLogin !== undefined) {
      let Data = JSON.parse(sessionStorage.isLogin);
      setName(Data.username);
    }
  }, [count]);

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(dataGet(0));
    dispatch(amountBlank());
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <Navbar
        sticky="top"
        style={{ backgroundColor: "#FA8072", color: "#ffff" }}
        expand="lg"
      >
        <Container>
          <Navbar.Brand className="mr-5">
            <Nav.Link as={Link} to="/">
              <b>GT SHOP</b>
            </Nav.Link>
          </Navbar.Brand>
          <Form className="ml-5 justify-content-center">
            <AutoCmpBox />
          </Form>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ border: "1 solid red" }}
          />

          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/all_product">
                Product
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  (window.location.href = "https://company.gtshopltd.com/")
                }
              >
                About us
              </Nav.Link>

              {count !== 0 ? (
                <Nav.Link as={Link} to="/checkout">
                  <ShoppingCartOutlined />
                  <span>{count}</span>
                </Nav.Link>
              ) : null}
              {user === false ? (
                <Nav.Link as={Link} to="/login">
                  Login
                </Nav.Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {Name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default NavBar;
