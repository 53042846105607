import React, {
  Component,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import {
  Card,
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { RegisteramiUser } from "../../../BackendData/FetchApi";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import OrderContext from "../../../Context/DataProvider";
import TransectionTab from "../../Component/Tab";
import { OrderInfo, OrderInfoId } from "../../../BackendData/FetchApi";
import "../../../style/Profile.css";
import ModalCMPProfile from "../../Component/ModalCMPProfile";

const Profile = () => {
  const [userDetails, setUserDetails] = useState("");
  const [PendingInfo, setPendingInfo] = useState([]);
  const [ApproveInfo, setApproveInfo] = useState([]);
  const [CompleteInfo, setCompleteInfo] = useState([]);
  const [PendingDetails, setPendingDetails] = useState([]);
  const [ApproveDetails, setApproveDetails] = useState([]);
  const [CompleteDetails, setCompleteDetails] = useState([]);

  useEffect(() => {
    if (sessionStorage.isLogin !== undefined) {
      setUserDetails(JSON.parse(sessionStorage.isLogin));
      let user = JSON.parse(sessionStorage.isLogin);
      OrderInfoId(user.id).then((res) => {
        setPendingInfo(res.dataPending.uniquePending);
        setApproveInfo(res.dataApprove.uniqueApprove);
        setCompleteInfo(res.dataComplete.uniqueComplete);
        setPendingDetails(res.dataPending.groupProductByOrderIdPending);
        setApproveDetails(res.dataApprove.groupProductByOrderIdApprove);
        setCompleteDetails(res.dataComplete.groupProductByOrderIdComplete);
      });
    } else {
    }
  }, []);

  return (
    <>
      <div>
        <div id="cardDiv">
          <Card className="m-2">
            <Card.Header>
              <h3>Profile</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={4} xs={12} style={{ textAlign: "center" }}>
                  <Image
                    id="image"
                    src={`https://shop.gtshopltd.com/public/images/users/${userDetails.user_image}`}
                    alt="car"
                    thumbnail
                  />
                  <div>
                    <ModalCMPProfile userDetails={userDetails} />
                  </div>
                </Col>

                <Col md={4} xs={12} style={{ textAlign: "center" }}>
                  <Container>
                    <Row id="name">
                      {" "}
                      <h4>{userDetails.name}</h4>
                    </Row>
                    <Row>
                      <h6>{userDetails.address}</h6>
                    </Row>
                    <Row>
                      <h6>{userDetails.mobile}</h6>
                    </Row>
                    <Row>
                      <h6>{userDetails.email}</h6>
                    </Row>
                  </Container>
                </Col>
                {/* <Col md={4} xs={12}>
                  <Container>
                    <Row>Total Cost</Row>
                    <Row>Total Payble</Row>
                    <Row>Total Deposit</Row>
                  </Container>
                </Col> */}
              </Row>
            </Card.Body>
          </Card>
        </div>
        <div id="underDiv">
          <OrderContext.Provider
            value={{
              PendingInfo,
              ApproveInfo,
              CompleteInfo,
              PendingDetails,
              ApproveDetails,
              CompleteDetails,
            }}
          >
            <TransectionTab />
          </OrderContext.Provider>
        </div>
      </div>
    </>
  );
};

export default Profile;
