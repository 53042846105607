import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { postLogin } from "../../../../BackendData/FetchApi";
import { Card, Button } from "react-bootstrap";
import { useState } from "react";

export default function Login() {
  const navigate = useNavigate();
  const [GetUser, setGetUser] = useState(null);
  const [CheckError, setCheckError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (data !== null) {
      postLogin(data).then((res) => {
        if (res !== "") {
          sessionStorage.setItem("isLogin", JSON.stringify(res));
          console.log(JSON.parse(sessionStorage.isLogin));
          let CheckCartProduct = JSON.parse(
            sessionStorage.getItem("CartProductList")
          );
          if (CheckCartProduct !== null) {
            navigate("/checkout");
            window.location.reload();
          } else {
            navigate("/");
            window.location.reload();
          }
        } else {
          //sessionStorage.setItem("isLogin", JSON.stringify(res));
          setCheckError(true);
          setInterval(() => {
            setCheckError(false);
          }, 3000);
        }
      });
    }
  };

  const ErrorMsg = () => {
    return <>Please check userId or Password</>;
  };

  return (
    <div className="login_wrapper">
      <Card style={{ width: "18rem" }} className="login_card_component">
        <Card.Header>Login</Card.Header>
        <Card.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="mb-3">
                  <label htmlFor="username">Username</label>
                  <input
                    {...register("username", { required: true })}
                    aria-invalid={errors.username ? "true" : "false"}
                    className="form-control"
                  />
                  {errors.username?.type === "required" && (
                    <p className="text-danger" role="alert">User Name is required</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    {...register("password", {
                      required: "true",
                    })}
                    aria-invalid={errors.password ? "true" : "false"}
                    className="form-control"
                  />
                  {errors.password && <p className="text-danger" role="alert">Password is required</p>}
                </div>
              </div>
              <div>{CheckError === true ? <ErrorMsg /> : null}</div>
              <div>
                <button
                  type="submit"
                  className="form-control btn btn-sm btn-success"
                >
                  Login
                </button>
              </div>
              <div className="mt-3">
                <span>
                  You have not account ?{" "}
                  <Link
                    to="/register"
                    className="form-control ml-2 btn btn-sm btn-success"
                  >
                    Register
                  </Link>
                </span>
              </div>
            </form>
            <Link
              to="/forget_password"
               className="form-control ml-2 mt-2 m "
            >
              Forget Password
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
